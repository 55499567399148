import dynamic from 'next/dynamic';
// import router, { useRouter } from 'next/router';
import { useRouter } from 'next/dist/client/router';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppSelector } from '../../redux/hooks';
import { GWMobileWrapper } from '../../styles/window.style';
import { saveEventV3 } from '../../utils/eventTracking';
import { Count, NumberCircleContainer } from '../Header/HeaderStyle';
import { MaskIcon } from '../Icons/Icons';
import {
  CartItemWrapper,
  CartItemWrapperPosition,
  MobileNavContainer,
  NavButton,
  NavButtonsContainer,
} from './MobileNavStyles';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

const MobileNav: React.FC = () => {
  const router = useRouter();

  const theme = useContext(ThemeContext);

  const { messages } = useLocale();
  const { home_msg, store_msg, categories_msg, cart_msg } = messages || {};

  const [tab, setTab] = useState<0 | 1 | 2 | 3 | 4 | 5 | null>(null);
  const [showMyShop, setShowMyShop] = useState<boolean>(false);
  const { cartItems } = useAppSelector((state) => state.cart);
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);

  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user && user.store_id) {
      setShowMyShop(true);
    } else {
      setShowMyShop(false);
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname === '/') {
      setTab(0);
    } else if (router.pathname.includes('/search')) {
      setTab(1);
    } else if (router.pathname.includes('/store')) {
      setTab(2);
    } else if (router.pathname === '/cart') {
      setTab(3);
    } else if (router.pathname === '/categories') {
      setTab(4);
    } else if (router.pathname.substring(5, 14) === '/selling/') {
      setTab(5);
    }
  }, [router.pathname]);

  useEffect(() => {
    let quantity = 0;
    for (let i = 0; i < cartItems.length; i++) {
      quantity = quantity + cartItems[i].qty;
    }
    setNumberOfCartItems(quantity);
  }, [cartItems]);

  const handleTabSelect = useCallback(
    (tab: 0 | 1 | 2 | 3 | 4 | 5 | null, label: string) => {
      setTab(tab);
      saveEventV3({
        category: 'mobile_nav',
        action: 'click',
        label: label,
        properties: String(tab),
        value: [''],
        from: router,
      });
    },
    [router]
  );

  return (
    <>
      <GWMobileWrapper>
        <MobileNavContainer>
          <NavButtonsContainer>
            <Link href={'/'} passHref>
              <NavButton
                selected={tab === 0}
                onClick={() => handleTabSelect(0, 'home')}
              >
                <MaskIcon
                  url={'/icons/home-24.svg'}
                  width="24px"
                  height="24px"
                  margin="0"
                  color={
                    tab === 0 ? theme.palette.text.t1 : theme.palette.text.t3
                  }
                  selected
                />
                <p>{home_msg}</p>
              </NavButton>
            </Link>

            <Link href={'/store'} passHref prefetch={false}>
              <NavButton
                selected={tab === 2}
                onClick={() => handleTabSelect(2, 'store')}
              >
                <MaskIcon
                  url={'/icons/controller-24.svg'}
                  width="24px"
                  height="24px"
                  margin="0"
                  color={
                    tab === 2 ? theme.palette.text.t1 : theme.palette.text.t3
                  }
                  selected
                />
                <p>{store_msg}</p>
              </NavButton>
            </Link>
            <Link href={'/cart'} passHref prefetch={false}>
              <NavButton
                selected={tab === 3}
                onClick={() => handleTabSelect(3, 'cart')}
              >
                <CartItemWrapper>
                  <CartItemWrapperPosition>
                    {numberOfCartItems > 0 ? (
                      <NumberCircleContainer>
                        <Count>{numberOfCartItems}</Count>
                      </NumberCircleContainer>
                    ) : null}
                  </CartItemWrapperPosition>
                </CartItemWrapper>
                <MaskIcon
                  url={'/icons/shopping-cart-24.svg'}
                  width="24px"
                  height="24px"
                  margin="0"
                  color={
                    tab === 3 ? theme.palette.text.t1 : theme.palette.text.t3
                  }
                  selected
                />
                <p>{cart_msg}</p>
              </NavButton>
            </Link>
            <Link href={'/categories'} passHref prefetch={false}>
              <NavButton
                selected={tab === 4}
                onClick={() => handleTabSelect(4, 'categories')}
              >
                <MaskIcon
                  url={'/icons/categories-24.svg'}
                  width="24px"
                  height="24px"
                  margin="0"
                  color={
                    tab === 4 ? theme.palette.text.t1 : theme.palette.text.t3
                  }
                  selected
                />
                <p>{categories_msg}</p>
              </NavButton>
            </Link>
            {showMyShop && (
              <Link
                href={`${'/user/selling/dashboard'}`}
                passHref
                prefetch={false}
              >
                <NavButton
                  selected={tab === 5}
                  onClick={() => handleTabSelect(5, 'my_shop')}
                >
                  <MaskIcon
                    url={'/icons/store-24.svg'}
                    width="24px"
                    height="24px"
                    margin="0"
                    color={
                      tab === 5 ? theme.palette.text.t1 : theme.palette.text.t3
                    }
                    selected
                  />

                  <p>My Shop</p>
                </NavButton>
              </Link>
            )}
          </NavButtonsContainer>
        </MobileNavContainer>
      </GWMobileWrapper>
    </>
  );
};

export default memo(MobileNav);
