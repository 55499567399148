import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const MobileNavContainer = styled.div`
  display: none;
  height: 55px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.divider.d1};
  background: ${(props) => props.theme.palette.background.bg1};
  position: fixed;
  bottom: -1px;
  box-shadow: ${(props) => props.theme.shadows.s3};
  z-index: 50;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
  }
`;

export const NavButtonsContainer = styled.div`
  justify-content: space-around;
  display: flex;
  max-width: 767px;
  margin: 0 auto;
`;

interface NavButtonProps {
  selected?: boolean;
}

export const NavButton = styled.button<NavButtonProps>`
  width: 72px;
  height: 56px;
  display: grid;
  grid-template-columns: 1fr;
  place-content: center;
  justify-items: center;
  grid-gap: 4px;
  color: ${(props) =>
    props.selected ? props.theme.palette.text.t1 : props.theme.palette.text.t2};
  & > p {
    font-size: 12px;
    line-height: 16px;
    font-family: ${(props) => (props.selected ? 'Onest-Bold' : 'Onest-Medium')};
  }
  &:active {
    color: ${COLORS.black};
  }
`;

export const CartItemWrapper = styled.div`
  position: relative;
`;

export const CartItemWrapperPosition = styled.div`
  position: absolute;
  top: -10px;
  right: -4px;
`;

export const SearchContainer = styled.input`
  padding: 15px;
  font-size: 1rem;
  line-height: 16px;
  font-family: Onest-Regular;
  color: ${COLORS.black};
  background: ${COLORS.gray1};
  width: 100%;
  border-radius: 8px;
  outline: none;
  &::placeholder {
    color: ${COLORS.gray3};
    font-size: 14px;
    line-height: 16px;
    font-family: Onest-Regular;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: 12px;
  border-top: 1px solid ${COLORS.gray1};
  padding-top: 24px;
`;

export const MobileSectionContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${COLORS.white};
  z-index: 16;
  height: 100vh;
`;

export const LoginContainer = styled.div`
  padding: 0 16px;
  & p {
    font-size: 24px;
    line-height: 30px;
    font-family: Onest-Regular;
    letter-spacing: -2%;
    color: ${(props) => props.theme.palette.text.t1};
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 54px;
  }
`;

export const ImageContainer = styled.div`
  width: fit-content;
  margin: 24px auto;
  & div {
    width: 191px;
    height: 191px;
    border-radius: 50%;
    background: ${COLORS.transparent};
  }
`;

export const SignupButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
  & > button {
    padding: 0;
    border: none;
    background: transparent;
  }

  & > button:last-of-type {
    background: ${(props) => props.theme.palette.background.bg1};
  }
`;

export const AuthButton = styled.button`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  align-items: center;
  justify-content: center;
`;
